import React from 'react';

import { Box } from '@mui/material';

import AbstractGrid from '../components/AbstractGrid';

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'created_at',
        headerName: 'Created At',
        width: 200,
        type: 'date',
        filterable: false,
        valueFormatter: (value) => {
            const date = new Date(value);
            return date.toLocaleString();
        },
    },
    { field: 'job_name', headerName: 'Job Name', width: 200 },
    { field: 'success_count', headerName: 'Success Count', width: 150 },
    { field: 'failure_count', headerName: 'Failure Count', width: 150 },
    { field: 'failure_message', headerName: 'Failure Message', width: 300, flex: 1 },
];

const rows = [];

const onRowClick = (event) => {
    console.debug(event);
};

function CronJobLogGrid() {
    return (
        <Box p={2}>
            <AbstractGrid
                dataType="cron_job_log"
                columns={columns}
                rows={rows}
                onRowClick={onRowClick}
                customFilters={{}}
            />
        </Box>
    );
}

export default CronJobLogGrid;