import React, { useState } from 'react';

import { Box } from '@mui/material';

import AbstractGrid from '../components/AbstractGrid';
import EmailDetailModal from '../components/EmailDetailModal';

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'created_at',
        headerName: 'Sent',
        width: 200,
        type: 'date',
        filterable: false,
        valueFormatter: (value) => {
            const date = new Date(value);
            return date.toLocaleString();
        },
    },
    { field: 'email_type', headerName: 'Email Type', width: 150 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'resent', headerName: 'Resent', width: 100 },
];

const rows = [];

function EmailLogGrid() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const onRowClick = (params) => {
        setSelectedRow(params.row);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedRow(null);
    };

    return (
        <>
            <Box p={2}>
                <AbstractGrid
                    dataType="email_log"
                    columns={columns}
                    rows={rows}
                    onRowClick={onRowClick}
                    customFilters={{}}
                />
            </Box>
            <EmailDetailModal
                open={isModalOpen}
                onClose={handleCloseModal}
                rowData={selectedRow}
            />
        </>
    );
}

export default EmailLogGrid;