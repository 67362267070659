import React, { useState, useEffect } from 'react';
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Snackbar,
    Alert,
    CircularProgress,
    Switch,
    FormControlLabel,
    Typography,
    TextField
} from '@mui/material';

import AbstractGrid from '../components/AbstractGrid';
import OrderDetailModal from '../components/OrderDetailModal';
import OrderClient from '../clients/OrderClient';


import useAuth from '../hooks/useAuth';
import useLocalStorage from '../hooks/useLocalStorage';

import './OrderGrid.css';

function OrderGrid() {
    const rows = [];
    const [isLoading, setIsLoading] = useState(false);
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const [autoReload, setAutoReload] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [selectedOrders, setSelectedOrders] = useState([]);
    const [massAction, setMassAction] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [searchTerm, setSearchTerm] = useState('');
    const [complianceStatus, setComplianceStatus] = useLocalStorage(
        'orderGridComplianceStatus',
        ['Error', 'NotCompliant', 'Pending']
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleComplianceChange = (e) => {
        setComplianceStatus(e.target.value);
    };

    const customFilters = {
        search_term: searchTerm,
        compliance_status: complianceStatus
    };

    // eslint-disable-next-line
    const { auth } = useAuth();

    useEffect(() => {
        const autoReloadInterval = 5000;
        let intervalId = null;

        if (autoReload) {
            intervalId = setInterval(() => {
                setReloadTrigger((cur) => cur + 1);
            }, autoReloadInterval);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [autoReload]);

    const handleAutoReloadChange = (event) => {
        setAutoReload(event.target.checked);
    };

    const onRowClick = (params) => {
        setSelectedRow(params.row);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedRow(null);
    };

    const handleSelectionModelChange = (selectionModel) => {
        setSelectedOrders(selectionModel);
    };

    const handleMassActionChange = (event) => {
        setMassAction(event.target.value);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleMassActionSubmit = async () => {
        try {
            setIsLoading(true);
            let response;
            switch (massAction) {
                case 'checkCompliance':
                    response = await OrderClient.checkCompliance(selectedOrders);
                    break;
                case 'commitOrders':
                    response = await OrderClient.commitOrders(selectedOrders);
                    break;
                case 'forceCompliance':
                    response = await OrderClient.forceCompliance(selectedOrders);
                    break;
                default:
                    break;
            }

            if (response) {
                const { successCount, failureCount, errorMessage, serverError } = response;
                if (errorMessage) {
                    console.debug('Error details:', errorMessage);
                }
                if (serverError) {
                    showSnackbar('Error performing mass action - check server logs.', 'error');
                } else {
                    let message = `${successCount} order(s) processed successfully.`;
                    if (failureCount > 0) {
                        message += ` ${failureCount} order(s) failed.`;
                    }
                    showSnackbar(message, failureCount > 0 ? 'warning' : 'success');
                }
            }
        } catch (error) {
            showSnackbar('Error performing mass action.', 'error');
            console.debug('Error performing mass action');
        }

        setReloadTrigger((cur) => cur + 1);
        setIsLoading(false);
    };

    /**
    * Configuration for time thresholds and corresponding colors.
    * This can be made more flexible to add more thresholds/ranges as needed.
    */
    const timeConfig = [
        {
            upperLimitHours: 4,
            color: 'green',
        },
        {
            upperLimitHours: 24,
            color: 'orange',
        },
        {
            upperLimitHours: Infinity,
            color: 'red',
        },
    ];

    /**
     * getDaysNotCompliantText
     * @param {Object} row - the entire row object with compliance_status, created_at, etc.
     * @param {Array} config - array of thresholds and color definitions
     * @returns {{ text: string, color: string }} or null
     */
    const getDaysNotCompliantText = (row, config = timeConfig) => {
        if (row.compliance_status !== 'NotCompliant') {
            return { text: '', color: '' };
        }

        const createdAt = new Date(row.created_at);
        const now = new Date();
        const diffMs = now - createdAt;
        const diffHours = diffMs / 1000 / 3600;

        let textValue = '';
        if (diffHours < 1) {
            textValue = '< 1 hr';
        } else if (diffHours < 24) {
            const hours = Math.floor(diffHours);
            textValue = `${hours} hr${hours === 1 ? '' : 's'}`;
        } else {
            const diffDays = Math.floor(diffHours / 24);
            if (diffDays < 2) {
                textValue = '1 day';
            } else {
                textValue = `${diffDays} day${diffDays === 1 ? '' : 's'}`;
            }
        }

        let color = '';
        for (const threshold of config) {
            if (diffHours <= threshold.upperLimitHours) {
                color = threshold.color;
                break;
            }
        }

        return { text: textValue, color };
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 70,
            filterable: false
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 200,
            type: 'date',
            filterable: true,
            valueFormatter: (value) => {
                const date = new Date(value);
                return date.toLocaleString();
            },
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 200,
            type: 'date',
            filterable: true,
            valueFormatter: (value) => {
                const date = new Date(value);
                return date.toLocaleString();
            },
        },
        {
            field: 'order_name',
            headerName: 'Order ID',
            width: 100,
            filterable: false
        },
        {
            field: 'order_size',
            headerName: 'Order Size',
            width: 200,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: ({ value }) => {
                if (!value) return 'N/A'
                if (!Array.isArray(value)) return 'N/A';

                const totalBottle = value.reduce((sum, item) => sum + (item.qty || 0), 0);
                const totalVolumeML = value.reduce((sum, item) => {
                    const qty = item.qty || 0;
                    const volume = item.volume_amount || 0;
                    return sum + (qty * volume);
                }, 0);
                const totalVolumeGallons = (totalVolumeML / 3785.41).toFixed(2);

                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '100%',
                    }}>
                        <Typography variant="body2" align="center">
                            Bottles: {totalBottle}
                        </Typography>
                        <Typography variant="body2" align="center">
                            Volume: {totalVolumeML} ml
                        </Typography>
                        <Typography variant="body2" align="center">
                            Volume: {totalVolumeGallons} gal
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'compliance_status',
            headerName: 'Compliance Status',
            width: 150,
            filterable: false,
            cellClassName: (params) => {
                if (params.value === 'Pending') return 'pending';
                if (params.value === 'Error' || params.value === 'NotCompliant') return 'error';
                if (['Compliant', 'Forced', 'Bypassed'].includes(params.value)) return 'compliant';
                return '';
            },
        },
        {
            field: 'days_not_compliant',
            headerName: 'Time Not Compliant',
            width: 160,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const { text, color } = getDaysNotCompliantText(params.row);

                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '100%',
                    }}>
                        <Typography variant="body2" style={{ color }}>
                            {text}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'next_ship_date',
            headerName: 'Next Ship Date',
            width: 180,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '100%',
                    }}>
                        <Typography variant="body2" style={{ 'color':'red' }}>
                            {params.value}
                        </Typography>
                    </Box>
                );
            },
        },
        {
            field: 'commit_status',
            headerName: 'Commit Status',
            width: 150,
            filterable: false,
            valueFormatter: (status) => {
                if (status) {
                    const words = status.split('-');
                    const formattedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
                    return formattedWords.join(' ');
                }
                return '';
            },
            cellClassName: (params) => {
                if (params.value === 'pending') return 'pending';
                if (params.value === 'error') return 'error';
                if (params.value === 'committed' || params.value === 'force-committed') return 'committed';
                return '';
            },
        },
        { field: 'email', headerName: 'Email', width: 300, filterable: false },
        { field: 'order_firstname', headerName: 'First Name', width: 130, filterable: false },
        { field: 'order_lastname', headerName: 'Last Name', width: 130, filterable: false },
        { field: 'order_shipping_state', headerName: 'Shipping State', width: 130, filterable: false },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            filterable: false,
            renderCell: (params) => {
                const { id, commit_status } = params.row;

                const handleAction = async (actionType) => {
                    try {
                        setIsLoading(true);
                        const response = await OrderClient[actionType]([id]);
                        const { successCount, failureCount, errorMessage, serverError } = response;
                        if (errorMessage) {
                            console.debug('Error details:', errorMessage);
                        }
                        if (serverError) {
                            showSnackbar('Error performing mass action - check server logs.', 'error');
                        } else {
                            let message = `${successCount} order(s) processed successfully.`;
                            if (failureCount > 0) {
                                message += ` ${failureCount} order(s) failed.`;
                            }
                            showSnackbar(message, failureCount > 0 ? 'warning' : 'success');
                        }
                        setReloadTrigger((cur) => cur + 1);
                    } catch (error) {
                        showSnackbar('Error performing mass action', 'error');
                        console.debug(`Error executing ${actionType}`, error);
                    } finally {
                        setIsLoading(false);
                    }
                };

                const checkDisabled = () => ['force-committed', 'committed'].includes(commit_status);
                const commitDisabled = () => ['force-committed', 'committed'].includes(commit_status);
                const forceCommitDisabled = () => ['force-committed', 'committed'].includes(commit_status);

                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Button
                            sx={{ marginTop: 1 }}
                            disabled={checkDisabled()}
                            variant="contained"
                            onClick={(e) => { e.stopPropagation(); handleAction('checkCompliance') }}
                            size="small">
                            Check Compliance
                        </Button>
                        <Button
                            disabled={commitDisabled()}
                            variant="contained"
                            onClick={(e) => { e.stopPropagation(); handleAction('commitOrders') }}
                            size="small">
                            Commit Order
                        </Button>
                        <Button
                            disabled={forceCommitDisabled()}
                            variant="contained"
                            onClick={(e) => { e.stopPropagation(); handleAction('forceCompliance') }}
                            size="small">
                            Force Compliance
                        </Button>
                    </Box>
                );
            },

        },
    ];

    return (
        <>
            <Box p={2}>
                <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
                    <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                        <InputLabel id="mass-action-label">Select Mass Action</InputLabel>
                        <Select
                            labelId="mass-action-label"
                            value={massAction}
                            onChange={handleMassActionChange}
                            label="Select Mass Action"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="checkCompliance">Check Compliance</MenuItem>
                            <MenuItem value="commitOrders">Commit Orders</MenuItem>
                            <MenuItem value="forceCompliance">Force Compliance</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={handleMassActionSubmit} disabled={!massAction || selectedOrders.length === 0}>
                        Submit
                    </Button>
                    {isLoading && <CircularProgress size={20} />}
                    <FormControlLabel
                        control={
                            <Switch
                                checked={autoReload}
                                onChange={handleAutoReloadChange}
                                color="primary"
                            />
                        }
                        label="Auto Reload"
                    />

                    <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                        <InputLabel id="compliance-status-label">Compliance Status</InputLabel>
                        <Select
                            labelId="compliance-status-label"
                            label="Compliance Status"
                            multiple
                            value={complianceStatus}
                            onChange={(e) => handleComplianceChange(e)}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            <MenuItem value="Error">Error</MenuItem>
                            <MenuItem value="NotCompliant">NotCompliant</MenuItem>
                            <MenuItem value="Pending">Pending</MenuItem>
                            <MenuItem value="Compliant">Compliant</MenuItem>
                            <MenuItem value="Bypassed">Bypassed</MenuItem>
                            <MenuItem value="Forced">Forced</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        variant="outlined"
                        size="small"
                        label="Search Orders"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{ minWidth: 300 }}
                    />
                </Box>
                <AbstractGrid
                    dataType="order"
                    columns={columns}
                    rows={rows}
                    onRowClick={onRowClick}
                    onSelectionModelChange={handleSelectionModelChange}
                    checkboxSelection={true}
                    reloadTrigger={reloadTrigger}
                    rowHeight={135}
                    customFilters={customFilters}
                />
            </Box>
            <OrderDetailModal
                open={isModalOpen}
                onClose={handleCloseModal}
                rowData={selectedRow}
                recordId={selectedRow?.id}
                model={'order'}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default OrderGrid;