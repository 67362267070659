import React, { useMemo, useEffect } from 'react';

import { getGridStringOperators, getGridDateOperators } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

import useDataGrid from '../hooks/useDataGrid';

function AbstractGrid(
    {
        dataType,
        columns,
        onRowClick,
        onSelectionModelChange,
        rowHeight,
        checkboxSelection,
        reloadTrigger,
        customFilters = {}
    }) {
    const {
        data,
        loading,
        rowCount,
        paginationModel,
        onFilterChange,
        onSortModelChange,
        onPaginationModelChange,
        columnVisibilityModel,
        onColumnVisibilityModelChange,
        reload } = useDataGrid(dataType, customFilters);

    const getUsedFilterFields = () => {
        return ['contains', 'equals', 'is', 'after', 'before'];
    }

    useEffect(() => {
        if (reloadTrigger > 0) {
            reload();
        }
        // eslint-disable-next-line
    }, [reloadTrigger]);

    //TODO - MIGHT HAVE TO APPLY FOR OTHER COLUMN TYPES
    let newColumns = useMemo(
        () =>
            columns.map((col) => {
                if (col.type === 'date') {
                    return {
                        ...col,
                        filterOperators: getGridDateOperators()
                            .filter((operator) => getUsedFilterFields().includes(operator.value))
                    };
                }

                return {
                    ...col,
                    filterOperators: getGridStringOperators()
                        .filter((operator) => getUsedFilterFields().includes(operator.value))
                };
            }),
        [columns],
    );

    const getRowHeight = () => {
        if (!rowHeight) {
            return 'auto';
        }

        return rowHeight;
    }


    return (
        <Box>
            <DataGridPro
                key={reloadTrigger}
                rows={data}
                columns={newColumns}
                pageSize={paginationModel.pageSize}
                onRowClick={onRowClick}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                sortingMode="server"
                onSortModelChange={onSortModelChange}
                rowCount={rowCount}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={onPaginationModelChange}
                loading={loading}
                getRowHeight={getRowHeight}
                onRowSelectionModelChange={onSelectionModelChange}
                checkboxSelection={checkboxSelection}
                disableRowSelectionOnClick
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            />
        </Box>
    );
}

export default AbstractGrid;
