import { useState, useEffect } from 'react';

/**
 * A custom hook to store a value in localStorage.
 * @param {string} key The key in localStorage
 * @param {*} defaultValue Default value if none is found in localStorage
 * @returns [value, setValue]
 */
export default function useLocalStorage(key, defaultValue) {
    const [value, setValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : defaultValue;
        } catch (error) {
            console.error('Error reading localStorage', error);
            return defaultValue;
        }
    });

    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error('Error writing localStorage', error);
        }
    }, [key, value]);

    return [value, setValue];
}
